import React, { useState } from 'react';
import Layout from '../../../components/Layout';

const FAQ = () => {
  return (
    <Layout fullMenu logoUrl="/FL/Cosmetology">
      <article id="main">
        <header>
          <h2>Frequently Asked Questions</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            <h4>How do I create an account?</h4>
            <p>
              Select the ”TAKE TEST” option on the homepage - there will be a field at the top of the test page where you can enter your
              email address. Once you successfully pass the test, a user account will automatically be created with the email address you
              used on the test page.
            </p>
            <h4>How do I pay for the course(s) I completed?</h4>
            <p>
              Once you pass the test, you will receive an email saying you passed. Click on the blue "Go to My Account" button at the bottom
              of the email. This will take you to your user dashboard. From here, click on the orange "Pay for Certificate" button. Fill out
              your personal and payment information and you are good to go!
            </p>
            <h4>When will I receive my certificate of completion?</h4>
            <p>Your certificate will be emailed to you instantly upon completion of the course.</p>

            <h4>How many hours am I required to complete to renew my license?</h4>
            <p>
              Professionals licensed by the Florida Board of Veterinary Medicine must complete 30 continuing education hours every biennium.
            </p>

            <h4>What Mandatory Topics am I Required to Complete?</h4>
            <p>Veterinarians are required to complete 1-Hour in Drug Dispensing and 2-Hours in Laws & Rules.</p>

            <h4>Does AdvanCE report my completed credits to the state?</h4>
            <p>AdvanCE sends daily completions of all courses to the DBPR. Weekend completions are reported on the following Monday.</p>

            <h4>When do I have to complete the Mandatory requirements by?</h4>
            <p>Florida Veterinarians are required to complete their requirements by May 31st.</p>

            <h4>If I lost my certificate, how do I receive another copy?</h4>
            <p>
              Sign into your account using your email address. From there, select the “My Account” option at the top of the page. This will
              take you to your user dashboard. All course(s) that you have completed will be displayed and you can email yourself a copy.
            </p>

            <h4>Does AdvanCE offer group discounts?</h4>
            <p>
              We offer discounts to organizations that have 5 or more individuals looking to complete our courses. Please call 352-207-1827
              if you are interested.
            </p>
            <h4>Does AdvanCE offer CE for other states?</h4>
            <p>We’re currently only offering continuing education for Veterinarians in Florida.</p>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default FAQ;
